import Cookies from 'js-cookie';

export const ACCESS_TOKEN_NAME = 'access_token';

export const COOKIES_CONSENT_NAME = 'cookies_consent';

export const setTokenToCookies = (token) => {
  Cookies.set(ACCESS_TOKEN_NAME, token);
};

export const getTokenFromCookies = () => {
  return Cookies.get(ACCESS_TOKEN_NAME) || '';
};

export const removeTokenFromCookies = () => {
  Cookies.remove(ACCESS_TOKEN_NAME);
};

export const setConsentToCookies = (value) => {
  Cookies.set(COOKIES_CONSENT_NAME, value);
};

export const getConsentFromCookies = () => {
  return Cookies.get(COOKIES_CONSENT_NAME) || '';
};

import { call, put, takeLatest } from 'redux-saga/effects';

import UserService from '#services/UserService';

import { getUser as getUserAction, getUserError, setUser } from './';

function* getUser() {
  try {
    const user = yield call(UserService.getMe);
    yield put(setUser(user));
  } catch (error) {
    console.log('getUser error:', error);
    put(getUserError());
  }
}

function* UserSaga() {
  yield takeLatest(getUserAction.type, getUser);
}

export default UserSaga;

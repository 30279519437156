export const FormItemType = {
  AsyncSelect: 'asyncSelect',
  CardNumber: 'cardNumber',
  Checkbox: 'checkbox',
  Code: 'code',
  DatePicker: 'datepicker',
  Email: 'email',
  Expire: 'expire',
  HoldersDeviceSelect: 'holdersDeviceSelect',
  Number: 'number',
  Password: 'password',
  RadioGroup: 'radiogroup',
  Row: 'row',
  Select: 'select',
  Switch: 'switch',
  Text: 'text',
  TextArea: 'textarea'
};

import { http } from '#utils/axios';

class ClientService {
  getClientInfo = async () => {
    const { data } = await http({
      url: `${this.endpoint}/`
    });
    return data;
  };

  getClientProfileTransactions = async (params) => {
    const { data } = await http({
      method: 'GET',
      params,
      url: `/client-profile-transactions`
    });

    return data;
  };

  updateProfile = async (data) => {
    const { data: result } = await http({
      data,
      method: 'PATCH',
      url: `${this.endpoint}`
    });
    return result;
  };

  constructor() {
    this.endpoint = '/client-profile';
    if (!ClientService.instance) {
      ClientService.instance = this;
    }
    return ClientService.instance;
  }
}

const instance = new ClientService();
Object.freeze(instance);

export default instance;

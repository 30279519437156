import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import UserService from '#services/UserService';

import { getUsers, setLoading, setUsers } from './';

function* getUsersList(data) {
  try {
    yield put(setLoading(true));
    const agents = yield call(UserService.getList, data?.payload);
    yield put(setUsers(agents));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setUsers({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* UsersSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getUsers.type, getUsersList);
}

export default UsersSaga;

import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import ManagerService from '#services/IncomeService';

import { getIncomes, getIncomesInBg, setIncomes, setLoading } from './';

function* getIncomesList(data) {
  try {
    yield put(setLoading(true));
    const result = yield call(ManagerService.getList, data?.payload);
    yield put(setIncomes(result));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setIncomes({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* getIncomesListBackGround(data) {
  try {
    const result = yield call(ManagerService.getList, data?.payload);
    yield put(setIncomes(result));
  } catch (error) {
    console.log(error);
  }
}

function* IncomesSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getIncomes.type, getIncomesList);

  yield debounce(
    DEFAULT_DEBOUNCE_DELAY,
    getIncomesInBg.type,
    getIncomesListBackGround
  );
}

export default IncomesSaga;

import { fork } from 'redux-saga/effects';

import IncomesSaga from './incomesPage/saga';
import OutcomesSaga from './outcomePage/saga';
import TransactionsSage from './transactionsPage/saga';
import UserSaga from './userData/saga';
import UsersSaga from './usersPage/saga';

export default function* () {
  yield fork(UserSaga);
  yield fork(UsersSaga);
  yield fork(IncomesSaga);
  yield fork(OutcomesSaga);
  yield fork(TransactionsSage);
}

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Space } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlagRu, FlagUA, FlagUS } from './Flags';
import { Stats } from './Stats';
import { UserMenu } from './UserMenu';

export const Header = ({ collapsed, setCollapsed }) => {
  const { i18n, t } = useTranslation();

  const lngs = [
    {
      icon: <FlagUA />,
      key: 'ua',
      label: <span style={{ marginLeft: '4px' }}>{t('Ukrainian')}</span>
    },
    {
      icon: <FlagUS />,
      key: 'en',
      label: <span style={{ marginLeft: '4px' }}>{t('English')}</span>
    },
    {
      icon: <FlagRu />,
      key: 'ru',
      label: <span style={{ marginLeft: '4px' }}>{t('Russian')}</span>
    }
  ];

  const getLanguage = () => i18next.language || window.localStorage.i18nextLng;

  const onChangeLng = (e) => {
    i18n.changeLanguage(e.key);
  };

  const language =
    lngs.find((el) => el.key === getLanguage().split('-')[0]) ||
    lngs.find((el) => el.key === 'en');

  console.log('language');

  return (
    <Layout.Header
      style={{
        alignItems: 'center',
        background: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0
      }}
    >
      <Button
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: '16px',
          height: 64,
          width: 64
        }}
        type="text"
      />

      <Stats />

      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          marginRight: '16px'
        }}
      >
        <Dropdown
          arrow={{
            pointAtCenter: true
          }}
          menu={{ items: lngs, onClick: onChangeLng }}
          placement="bottomRight"
          trigger={['click']}
        >
          <Button style={{ marginRight: '16px' }}>
            <Space>
              <div
                style={{ alignItems: 'center', display: 'flex', gap: '5px' }}
              >
                {language.icon}
                {language.label}
              </div>
            </Space>
          </Button>
        </Dropdown>
        <UserMenu />
      </div>
    </Layout.Header>
  );
};

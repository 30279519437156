import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import ClientService from '#services/ClientService';

import { getTransactions, setLoading, setTransactions } from './';

function* getTransactionsList(data) {
  try {
    yield put(setLoading(true));
    const agents = yield call(
      ClientService.getClientProfileTransactions,
      data?.payload
    );
    yield put(setTransactions(agents));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setTransactions({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* TransactionsSaga() {
  yield debounce(
    DEFAULT_DEBOUNCE_DELAY,
    getTransactions.type,
    getTransactionsList
  );
}

export default TransactionsSaga;

import { lazy } from 'react';

import { LayoutTypes, Urls } from '#constants';

export const ROUTES = [
  {
    component: lazy(() => import('./HomePage')),
    id: 1,
    layout: LayoutTypes.Base,
    path: Urls.HomePage,
    protected: true,
    roles: ['client', 'clientAgent']
  },
  {
    component: lazy(() => import('./LoginPage')),
    id: 2,
    path: Urls.LoginPage,
    protected: false,
    roles: ['client', 'clientAgent']
  },
  {
    component: lazy(() => import('./OutcomePage')),
    id: 3,
    layout: LayoutTypes.Base,
    path: Urls.OutcomePage,
    protected: true,
    roles: ['client', 'clientAgent']
  },
  {
    component: lazy(() => import('./IncomePage')),
    id: 4,
    layout: LayoutTypes.Base,
    path: Urls.IncomePage,
    protected: true,
    roles: ['client', 'clientAgent']
  },
  {
    component: lazy(() => import('./ProfilePage')),
    id: 5,
    layout: LayoutTypes.Base,
    path: Urls.Profile,
    protected: true,
    roles: ['client', 'clientAgent']
  }
];

import { Layout } from 'antd';
import React, { useState } from 'react';

import { Header } from './Header';
import { Sidebar } from './Sidebar';

import classes from './styles.module.scss';

const { Content } = Layout;

export const BaseLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className={classes.layout}>
      <Sidebar collapsed={collapsed} />
      <Layout>
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content className={classes.contentWrapper}>{children}</Content>
      </Layout>
    </Layout>
  );
};

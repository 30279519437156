/* eslint-disable perfectionist/sort-objects */
export const Urls = {
  LoginPage: '/login',
  OutcomePage: '/outcome',
  IncomePage: '/income',
  Profile: '/profile',
  Operators: '/operators',
  Cards: '/cards',
  Tariffs: '/tariffs',
  Banks: '/banks',
  Devices: '/devices',
  Holders: '/holders',
  Agents: '/agents',
  Notifications: '/notifications',
  Corrections: '/corrections',
  Debtors: '/debtors',
  Transactions: '/transactions'
};

const defaultState = {
  count: 0,
  data: [],
  isLoading: true
};

export const initialStore = {
  clientsPage: defaultState,
  incomesPage: defaultState,
  outcomesPage: defaultState,
  transactionsPage: defaultState,
  userData: {
    isLoading: true,
    user: null
  },
  usersPage: defaultState
};

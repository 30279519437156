import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class IncomeService extends BaseService {
  createCheckOperation = async (data) => {
    const { data: result } = await http({
      data,
      method: 'POST',
      url: `${this.endpoint}/check`
    });
    return result;
  };

  constructor() {
    super('/income-operation');
    if (!IncomeService.instance) {
      IncomeService.instance = this;
    }
    return IncomeService.instance;
  }
}

const instance = new IncomeService();
Object.freeze(instance);

export default instance;

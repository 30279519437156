export const incomeOperationStatuses = {
  AutoCompleted: 'completed-auto',
  CanceledByClient: 'canceled-by-client',
  CanceledByOperator: 'canceled-by-operator',
  CanceledByTime: 'canceled-by-time',
  CheckingByOperator: 'checking-by-operator',
  CheckingBySystem: 'checking-by-system',
  CompletedFact: 'completed-fact',
  CompletedFull: 'completed-full',
  Created: 'created',
  NeedResolve: 'need-resolve',
  Processing: 'processing',
  Recalculation: 'recalculation'
};

export const incomeClientStatuses = {
  Paid: 'paid',
  Rejected: 'rejected',
  WaitPayment: 'wait-payment'
};

export const incomeOperatorStatuses = {
  Approved: 'approved',
  ApprovedPartial: 'approved-partial',
  InWork: 'in-work',
  None: 'none',
  Rejected: 'rejected',
  SendToResolve: 'send-to-resolve'
};

export const outcomeOperatorStatuses = {
  Approved: 'approved',
  InWork: 'in-work',
  None: 'none',
  ReAssign: 're-assign',
  Rejected: 'rejected'
};

export const outcomeClientStatuses = {
  Rejected: 'rejected'
};

export const outcomeOperationStatuses = {
  CanceledByClient: 'canceled-by-client',
  Completed: 'completed',
  Created: 'created',
  Processing: 'processing',
  Rejected: 'rejected'
};

import { combineReducers } from '@reduxjs/toolkit';

import { reducer as incomesPageReducer } from './incomesPage';
import { reducer as outcomesPageReducer } from './outcomePage';
import { reducer as transactionsPageReducer } from './transactionsPage';
import { reducer as userDataReducer } from './userData';
import { reducer as usersPageReducer } from './usersPage';

export default combineReducers({
  incomesPage: incomesPageReducer,
  outcomesPage: outcomesPageReducer,
  transactionsPage: transactionsPageReducer,
  userData: userDataReducer,
  usersPage: usersPageReducer
});

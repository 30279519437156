import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getUser = createAction('userData/GET_USER');

const userDataSlice = createSlice({
  initialState: initialStore.userData,
  name: 'userData',
  reducers: {
    getUserError: (state) => {
      state.isLoading = false;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
      state.isLoading = false;
    },
    updateUser: (state, { payload }) => {
      state.user = {
        ...state.user,
        ...payload
      };
    }
  }
});
export const { getUserError, setUser, updateUser } = userDataSlice.actions;

export const { reducer } = userDataSlice;

import { http } from '#utils/axios';

class StatsService {
  constructor() {
    this.endpoint = '/stats';

    if (!StatsService.instance) {
      StatsService.instance = this;
    }
    return StatsService.instance;
  }

  getStats = async () => {
    const { data } = await http({
      url: this.endpoint
    });
    return data;
  };
}

const instance = new StatsService();
Object.freeze(instance);

export default instance;

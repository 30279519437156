import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getOutcomes = createAction('outcomes/GET_OUTCOMES');
export const getOutcomesInBg = createAction('outcomes/GET_OUTCOMES_IN_BG');

const outcomesSlice = createSlice({
  initialState: initialStore.outcomesPage,
  name: 'outcomesPage',
  reducers: {
    removeOutcome: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setOutcomes: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    updateOutcome: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const { removeOutcome, setLoading, setOutcomes, updateOutcome } =
  outcomesSlice.actions;

export const { reducer } = outcomesSlice;

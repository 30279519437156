import axios from 'axios';

import { getTokenFromCookies, removeTokenFromCookies } from './cookies-actions';

const defaultConfig = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'content-type': 'application/json'
  },
  timeout: 10000
};

export const instance = axios.create(defaultConfig);

instance.interceptors.request.use(
  (config) => {
    if (config.headers?.Authorization) return config;

    const token = getTokenFromCookies();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token || process.env.REACT_APP_MASTER_KEY}`
      }
    };
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response.status === 401 &&
      error?.response.data?.error?.message === 'Unauthorized' &&
      window.location.pathname !== '/login'
    ) {
      removeTokenFromCookies();
      window.open('/login', '_self');
    }
    return Promise.reject(error);
  }
);

export const http = async ({ data, method = 'GET', params, url, ...rest }) =>
  instance({ data, method, params, url, ...rest });

import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class OutcomeService extends BaseService {
  constructor() {
    super('/outcome-operation');
    if (!OutcomeService.instance) {
      OutcomeService.instance = this;
    }
    return OutcomeService.instance;
  }

  getOutcomeCard = async (id) => {
    const { data } = await http({
      url: `${this.endpoint}/${id}/allowed-cards`
    });
    return data;
  };

  getCalculations = async (id, cards) => {
    const { data } = await http({
      data: { cards },
      method: 'PATCH',
      url: `${this.endpoint}/${id}/cards`
    });

    return data;
  };

  sendToTelegram = async (id) => {
    return await http({
      method: 'GET',
      url: `${this.endpoint}/${id}/notify-telegram`
    });
  };
}

const instance = new OutcomeService();
Object.freeze(instance);

export default instance;

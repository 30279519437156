import { LayoutTypes } from '#constants';

import { BaseLayout } from './BaseLayout';

export const Layouts = ({ children, layout }) => {
  switch (layout) {
    case LayoutTypes.Base: {
      return <BaseLayout>{children}</BaseLayout>;
    }
    default: {
      return <>{children}</>;
    }
  }
};

import i18next from 'i18next';

class ErrorMessages {
  get MaxLength() {
    return i18next.t('This field is too long');
  }

  get MinLength() {
    return i18next.t('This field is too short');
  }

  get Password() {
    return i18next.t('The passwords do not match');
  }

  get PatternCard() {
    return i18next.t('Invalid card number');
  }

  get PatternEmail() {
    return i18next.t('Invalid email address');
  }

  get PatternLetters() {
    return i18next.t('This field can only contain letters');
  }

  get PatternNumbers() {
    return i18next.t('This field can only contain numbers');
  }

  get PatternPhone() {
    return i18next.t('Invalid phone number');
  }

  get Required() {
    return i18next.t('This field is required');
  }

  get Url() {
    return `${i18next.t(
      'Please enter a valid website URL'
    )} (e.g. http://www.domain.com)`;
  }
}

const ErrorMessagesInstance = new ErrorMessages();
export { ErrorMessagesInstance as ErrorMessages };

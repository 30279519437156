import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';
import { removeTokenFromCookies } from '#utils/cookies-actions';

class UserService extends BaseService {
  getAllowedRoles = async (params) => {
    return http({
      params,
      url: `${this.endpoint}/roles/allowed`
    }).then(({ data }) => ({
      next: data.next,
      result: data.map((el) => {
        return {
          label: el.name,
          value: el.id
        };
      })
    }));
  };

  getList = async (params) => {
    const { data } = await http({
      params,
      url: `${this.endpoint}/all`
    });
    return data;
  };

  getMe = async () => {
    const { data } = await http({
      url: `${this.endpoint}/me`
    });
    return data;
  };

  getUsersByRole = async (role) => {
    const { data } = await http({
      url: `${this.endpoint}/all/by?role=${role}`
    });

    return {
      next: false,
      result: data.map((el) => ({
        label: el.name,
        value: el.id
      }))
    };
  };

  updateConnectionStatus = async (userId, connectionId, status) => {
    const { data } = await http({
      data: { isBlocked: status },
      method: 'PATCH',
      url: `${this.endpoint}/${userId}/connection/${connectionId}`
    });
    return data;
  };

  updateUserPassword = async (userId, password) => {
    return await http({
      data: { newPass: password },
      method: 'PATCH',
      url: `${this.endpoint}/${userId}/update-password`
    });
  };

  logout = async () => {
    removeTokenFromCookies();

    window.location.href = '/login';
  };

  constructor() {
    super('/user');
    if (!UserService.instance) {
      UserService.instance = this;
    }
    return UserService.instance;
  }
}

const instance = new UserService();
Object.freeze(instance);

export default instance;

import { Loading3QuartersOutlined } from '@ant-design/icons';
import React from 'react';

import classes from './styles.module.scss';

export const Loader = ({ isBig }) => {
  return (
    <div className={classes.wrapper}>
      <Loading3QuartersOutlined
        spin
        style={{ color: '#2371d7', fontSize: isBig ? 72 : 50 }}
      />
    </div>
  );
};

import { http } from '#utils/axios';

export class BaseService {
  createItem = async (value) => {
    try {
      const { data } = await http({
        data: value,
        method: 'POST',
        url: this.endpoint
      });

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  deleteItem = async (id) => {
    await http({
      method: 'DELETE',
      url: `${this.endpoint}/${id}`
    });
  };

  editItem = async ({ id, ...values }) => {
    const { data } = await http({
      data: values,
      method: 'PATCH',
      url: `${this.endpoint}/${id}`
    });

    return data;
  };

  getList = async (params) => {
    const { data } = await http({
      params,
      url: this.endpoint
    });
    return data;
  };

  getListOptions = async (params) => {
    const { data } = await http({
      params,
      url: `${this.endpoint}/list`
    });
    return data;
  };

  constructor(endpoint) {
    this.endpoint = endpoint;
  }
}

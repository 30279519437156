import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import UserService from '#services/UserService';

function getItem(label, key, icon, children, type) {
  return {
    children,
    icon,
    key,
    label,
    type
  };
}

export const UserMenu = () => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    let itemsArr = [];

    itemsArr.push(getItem(t('Logout'), 'logout', null, null, 'item'));

    return itemsArr;
  }, [t]);

  const onItemClick = (e) => {
    if (e.key === 'logout') {
      UserService.logout();
    }
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <Dropdown
        arrow={{
          pointAtCenter: true
        }}
        menu={{ items: items, onClick: onItemClick }}
        mode="vertical"
        placement="bottomRight"
        trigger={['click']}
      >
        <Space>
          <Avatar
            icon={<UserOutlined />}
            style={{ backgroundColor: '#87d068' }}
          />
        </Space>
      </Dropdown>
    </div>
  );
};

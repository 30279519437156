import {
  LoginOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Urls } from '#constants';

import logoSmall from './logo_small.png';

import classes from './styles.module.scss';

const { Sider } = Layout;

export const Sidebar = ({ collapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const getItem = useCallback(
    (label, key, icon, children, type) => ({
      children,
      icon,
      key,
      label,
      type
    }),
    []
  );

  const selectedKey = useMemo(() => {
    const urls = Object.values(Urls).slice(1);

    return (
      urls.findIndex((el) => {
        return location.pathname.includes(el);
      }) + 1 || 1
    );
  }, [location]);

  return (
    <Sider collapsed={collapsed} collapsible trigger={null}>
      <NavLink to={Urls.OutcomePage}>
        <div className={classes.logo}>
          <img alt={'small logo'} src={logoSmall} />
        </div>
      </NavLink>

      <Menu
        items={[
          getItem(
            <NavLink to={Urls.OutcomePage}>{t('Payouts')}</NavLink>,
            '1',
            <LogoutOutlined />
          ),
          getItem(
            <NavLink to={Urls.IncomePage}>{t('Receipts')}</NavLink>,
            '2',
            <LoginOutlined />
          ),
          getItem(
            <NavLink to={Urls.Profile}>{t('Profile')}</NavLink>,
            '3',
            <UserOutlined />
          )
        ].flat()}
        mode="inline"
        selectedKeys={[selectedKey.toString()]}
        theme="dark"
      />
    </Sider>
  );
};

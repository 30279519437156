import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getUsers = createAction('users/GET_USERS');

const usersSlice = createSlice({
  initialState: initialStore.usersPage,
  name: 'usersPage',
  reducers: {
    removeUser: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setUsers: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    updateConnection: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.userId) {
          return {
            ...item,
            connections: item.connections.map((connection) => {
              if (connection.id === payload.id) {
                return {
                  ...connection,
                  isBlocked: payload.isBlocked
                };
              }
              return connection;
            })
          };
        }
        return item;
      });
    },
    updateUser: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const {
  removeUser,
  setLoading,
  setUsers,
  updateConnection,
  updateUser
} = usersSlice.actions;

export const { reducer } = usersSlice;
